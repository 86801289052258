'use client'

import {useTranslate} from '@/hooks/useTranslate'
import {useGlobalStore} from '@/store'
import Link from 'next/link'
import {useRouter} from 'next/navigation'
import PrimaryButton from 'tofisa-package/dist/components/Button/PrimaryButton'
import BaseImage from 'tofisa-package/dist/components/Images/BaseImage'
import {useShallow} from 'zustand/react/shallow'

const continueShopping = !!process.env.NEXT_PUBLIC_CONTINUE_SHOPPING_URL
  ? process.env.NEXT_PUBLIC_CONTINUE_SHOPPING_URL
  : '/'

const Custom404 = ({messages}: {messages: string}) => {
  const router = useRouter()

  const {categories} = useGlobalStore(
    useShallow((state) => ({
      categories: state.categories,
    }))
  )

  const t = messages
    ? (key: string) => {
        return messages[key as keyof typeof messages]
      }
    : useTranslate

  const drawerCategoryFunc = (id: string, slug: string) => {
    router.push(`/categories/${slug}-c-${id}`)
  }

  return (
    <div className='notfound_container'>
      <BaseImage inpImg='mainLogo' logo='/v2/Assets/Icons/Custom404.svg' />
      <div className='body-regular mainLogoText text-center mt-3 text-fontcolor col-9 col-lg-3'>
        {t('ERROR_404') as string}
      </div>

      <Link href={continueShopping}>
        <PrimaryButton
          title={t('BACK_TO_SHOPPING') as string}
          primaryBtnClass='text-white errorBtnClass'
        />
      </Link>
      <div className='d-flex flex-wrap mt-4 iconCursor'>
        {!!categories &&
          categories.map((dr, i) => (
            <div
              key={i}
              className='col-3 text-center'
              onClick={() => drawerCategoryFunc(dr.category_id.toString(), dr.slug)}
            >
              <div className='categoryList py-2 m-1'>{dr.name}</div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Custom404
